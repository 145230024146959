<template>
  <div>
    <page-heading :heading="$t('reports')">
      <template v-slot:actions>
        <app-button :label="$t('create')" @click="createAsset()"></app-button>
        <app-options>
          <app-option :label="$t('export')" svg="document-download" @click="exportAssetReports"/>
        </app-options>
      </template>
    </page-heading>

    <form-open class="grid grid-cols-4 gap-4 mb-10" @submit="submit">
      <form-select
        :label="$t('presets')"
        :options="data.presets"
        :required="false"
        :show-validation-label="false"
        input-id="filters"
        v-model="preset"
      />
      <form-text
        :label="$t('id')"
        :required="false"
        :show-validation-label="false"
        input-id="id"
        v-model="query.filter.id"
      />
      <form-text
        :label="$t('asset_ref')"
        :required="false"
        :show-validation-label="false"
        icon="qrcode"
        input-id="asset_ref"
        v-model="query.filter.assetRef"
      />
      <form-select
        :label="$t('categories')"
        :multiple="true"
        :options="data.assetCategories"
        :placeholder="$t('select_placeholder_all')"
        :required="false"
        :show-validation-label="false"
        @input="submit"
        input-id="categories"
        v-model="query.filter.assetCategories"
      />
      <form-select
        :label="$t('action_req')"
        :options="data.yes_no"
        :placeholder="$t('select_placeholder_all')"
        :required="false"
        :show-validation-label="false"
        @input="submit"
        input-id="action_req"
        v-model="query.filter.action_required"
      />
      <form-select
        :label="$t('model.asset_report.reported_by')"
        :loading="data.usersLoading"
        :multiple="true"
        :options="data.users"
        :placeholder="$t('select_placeholder_all')"
        :required="false"
        :show-validation-label="false"
        @input="submit"
        input-id="user"
        v-model="query.filter.user_id"
      />
      <form-select
        :label="$t('order_by')"
        :multiple="true"
        :options="data.sorts"
        :required="false"
        :show-validation-label="false"
        @input="submit"
        input-id="order_by"
        v-model="query.sort"
      />
      <form-text
        :label="$t('date_from')"
        :required="false"
        :show-validation-label="false"
        @input="submit"
        icon="calendar"
        input-id="date_from"
        type="date"
        v-model="query.filter.date_from"
        v-show="showAdvancedFilters"
      />
      <form-text
        :label="$t('date_to')"
        :required="false"
        :show-validation-label="false"
        @input="submit"
        input-id="date_to"
        type="date"
        v-model="query.filter.date_to"
        v-show="showAdvancedFilters"
      />
      <form-text
        :label="$t('action_req_date_from')"
        :required="false"
        :show-validation-label="false"
        @input="submit"
        icon="calendar"
        input-id="action_req_date_from"
        type="date"
        v-model="query.filter.action_required_date_from"
        v-show="showAdvancedFilters"
      />
      <form-text
        :label="$t('action_req_date_to')"
        :required="false"
        :show-validation-label="false"
        @input="submit"
        icon="calendar"
        input-id="action_req_date_to"
        type="date"
        v-model="query.filter.action_required_date_to"
        v-show="showAdvancedFilters"
      />
      <form-select
        :label="$t('orphaned')"
        :options="data.yes_no"
        :placeholder="$t('select_placeholder_all')"
        :required="false"
        :show-validation-label="false"
        @input="submit"
        input-id="orphaned"
        v-model="query.filter.orphaned"
        v-show="showAdvancedFilters"
      />
      <app-button class="hidden"/>
    </form-open>

    <loading-wrapper>
      <app-table :items="items">
        <template
          v-slot:headings
        >
          <app-th :heading="$t('model.asset_report.id')"/>
          <app-th :heading="$t('model.asset_report.asset')"/>
          <app-th-sortable :label="$t('model.asset_report.action_required')" name="action_required" :sort="query.sort" @updateSort="updateSort"/>
          <app-th :heading="$t('model.asset_report.reported_by')"/>
          <app-th-sortable :label="$t('model.asset_report.created_at')" name="created_at" :sort="query.sort" @updateSort="updateSort"/>
          <app-th :heading="$t('model.asset_report.location')"/>
          <app-th :heading="$t('model.asset_report.notes')"/>
          <app-th/>
        </template>

        <template
          v-slot:row="{ item }"
        >
          <app-td>
            <a href="#" class="link font-bold" @click.prevent="editModel(item.id)">{{ item.id }}</a>
          </app-td>
          <app-td>
            <template v-if="item.asset">
              <span class="block">{{ item.asset.name }}</span>
              <span class="text-xs text-gray-400">{{ item.asset.ref }}</span>
            </template>
            <template v-else>
              <div class="flex flex-row-reverse justify-end items-center gap-2">
                <app-svg class="h-4 text-red-200" svg="exclamation-circle"/>
                <span>{{ item.asset_unknown_ref }}</span>
              </div>
            </template>
          </app-td>
          <app-td>
            <span
              class="px-2 inline-flex"
              v-if="item.action_required"
              :class="{
                'bg-yellow-100 text-yellow-800': item.action_required_future === true,
                'bg-red-100 text-red-800': item.action_required_future === false,
              }"
            >
              {{ moment(item.action_required, moment.ISO_8601).format('LLLL') }}
            </span>
            <span class="px-2 inline-flex bg-green-100 text-green-800" v-else>{{ $t('no_action_required') }}</span>
          </app-td>
          <app-td>{{ item.user ? item.user.name : '-' }}</app-td>
          <app-td>
            <span :title="item.created_at">{{ item.created_at_diff }}</span>
            <span class="block text-xs text-gray-600">{{ moment(item.created_at, moment.ISO_8601).format('LLLL') }}</span>
          </app-td>
          <app-td>
            <template v-if="item.location">
              <!--
              <a
                :href="`https://maps.google.com/?q=${item.location.lat},${item.location.lng}`"
                class="hover:text-app-orange"
                target="_blank"
              >
                <app-svg class="h-4" svg="location-marker"/>
              </a>
              -->
              <app-svg
                @click="editModel(item.id, 'location')"
                class="h-4 cursor-pointer hover:text-app-orange"
                svg="location-marker"
              />
            </template>
            <template v-else>
              {{ $t('na') }}
            </template>
          </app-td>
          <app-td>
            <badge
              :label="item.notes_count"
              @click="editModel(item.id, 'notes')"
              class="cursor-pointer"
            />
          </app-td>
          <app-td>
            <app-options>
              <app-option-edit @click="editModel(item.id)"/>
              <app-option-delete @click="openDeleteModal(item.id)"/>
            </app-options>
          </app-td>
        </template>
      </app-table>
      <pagination
        :meta="meta"
        :page="query.page"
        v-if="meta"
        @pageUpdated="updatePage"
      />
    </loading-wrapper>

    <modal-delete :active="modals.delete" @close="closeModal('delete')" @delete="deleteModel(modelId)"
      :title="$t('delete')"
      :text="$t('delete_thing_text', { thing: model.id })"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import ApiAssetCategoryService from '@/services/api/asset_category';
import ApiAssetReportService from '@/services/api/asset_report';
import ApiUserService from '@/services/api/user';
import Common from '@/mixins/common';
import moment from 'moment';
import { saveAs } from 'file-saver';
import config from '@/config';

export default {
  computed: {
    moment: () => moment,
    presetFilters() {
      const actionReq = {
        filter: {
          action_required: 1,
        },
        sort: [
          'action_required',
        ],
      };

      return {
        actionReqMonth: {
          ...actionReq,
          filter: {
            action_required_date_to: moment().add(1, 'month').format('YYYY-MM-DD'),
          },
        },
        actionReqThreeMonth: {
          ...actionReq,
          filter: {
            action_required_date_to: moment().add(3, 'month').format('YYYY-MM-DD'),
          },
        },
      };
    },
    showAdvancedFilters() {
      return true;
    },
  },
  created() {
    this.debounceSearchUsers = _.debounce(this.searchUsers, 1000, {
      leading: true,
    });
  },
  data() {
    return {
      data: {
        assetCategories: [],
        presets: [
          {
            value: 'actionReqMonth',
            label: this.$t('action_req_month'),
          },
          {
            value: 'actionReqThreeMonth',
            label: this.$t('action_req_three_month'),
          },
        ],
        sorts: [
          {
            value: 'action_required',
            label: this.$t('action_req_asc'),
          },
          {
            value: '-action_required',
            label: this.$t('action_req_desc'),
          },
          {
            value: 'created_at',
            label: this.$t('created_at_asc'),
          },
          {
            value: '-created_at',
            label: this.$t('created_at_desc'),
          },
        ],
        users: [],
        usersLoading: false,
        yes_no: [
          {
            value: '1',
            label: this.$t('yes'),
          },
          {
            value: '0',
            label: this.$t('no'),
          },
        ],
      },
      items: [],
      model: {}, // Used by delete modal.
      modelId: {},
      meta: {},
      modals: {
        delete: false,
      },
      preset: null,
      query: {
        ...this.emptyQuery(),
      },
      // showAdvancedFilters: false,
    };
  },
  methods: {
    createAsset() {
      this.$router.push({
        name: 'asset_reports.create',
      });
    },
    deleteModel(modelId) {
      ApiAssetReportService.deleteOrganisationAssetReport(modelId)
        .then(() => {
          this.closeModals();
          this.getListings();
        })
        .catch(() => {});
    },
    editModel(modelId, hash = null) {
      const location = {
        name: 'asset_reports.edit',
        params: {
          assetReportId: modelId,
        },
      };

      if (hash) {
        location.hash = `#${hash}`;
      }

      this.$router.push(location);
    },
    emptyQuery() {
      return {
        filter: {},
        page: 1,
        per_page: config.app.pagination.per_page,
        sort: [
          '-created_at',
        ],
        ...this.$route.query,
      };
    },
    exportAssetReports() {
      ApiAssetReportService.exportOrganisationAssetReports({
        params: this.query,
        responseType: 'blob',
      }).then((response) => {
        saveAs(response.data, `asset_reports_${Date.now()}.csv`);
      });
    },
    filterByAssetRef(assetRef) {
      this.query.filter.assetRef = assetRef;
      this.submit();
    },
    getAndSetModel(modelId) {
      return ApiAssetReportService.getOrganisationAssetReport(modelId)
        .then((response) => {
          const { data } = response.data;

          this.modelId = modelId;
          this.model = this.resourceToModel(data);
        });
    },
    getListings() {
      ApiAssetReportService.getOrganisationAssetReports({
        params: {
          ...this.query,
          include: [
            'asset',
            'location',
            'notesCount',
            'user',
          ],
        },
      })
        .then((response) => {
          const { data, meta } = response.data;

          this.items = data;
          this.meta = meta;
        })
        .catch(() => {});
    },
    openDeleteModal(modelId) {
      this.getAndSetModel(modelId).then(() => {
        this.openModal('delete');
      });
    },
    resourceToModel(data) {
      return {
        id: data.id,
      };
    },
    routeQueryUpdated() {
      // ...
    },
    searchUsers(searchQuery) {
      this.data.usersLoading = true;
      ApiUserService.getOrganisationUsers({
        params: {
          filter: {
            search: searchQuery,
          },
          per_page: -1,
          sort: 'last_name',
        },
      })
        .then((response) => {
          const { data } = response.data;

          this.data.users = this.toSelectOptions(data);
        })
        .catch(() => {})
        .finally(() => {
          this.data.usersLoading = false;
        });
    },
    submit() {
      this.query.page = 1;
      this.getListings();
    },
  },
  mixins: [
    Common,
  ],
  mounted() {
    ApiAssetCategoryService.getAssetCategories({
      params: {
        sort: 'name',
      },
    })
      .then((response) => {
        const { data } = response.data;

        this.data.assetCategories = this.toSelectOptions(data);
      })
      .catch();

    ApiUserService.getOrganisationUsers({
      params: {
        per_page: -1,
        sort: 'last_name',
      },
    })
      .then((response) => {
        const { data } = response.data;

        this.data.users = this.toSelectOptions(data);
      })
      .catch(() => {});

    this.getListings();
  },
  watch: {
    preset: {
      handler(preset) {
        if (this.presetFilters[preset]) {
          this.query = this.presetFilters[preset];
        } else {
          this.query = this.emptyQuery();
        }

        this.submit();
      },
    },
  },
};
</script>
